import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Alert,
  InputAdornment,
  IconButton,
  Paper,
  Stack,
} from "@mui/material";
import AuthService from "../services/AuthService";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await AuthService.login(username, password);
      onLogin();
    } catch (error) {
      setMessage("Login failed. Please check your credentials.");
    }
  };

  return (
      <Box
        sx={{
          // backgroundImage: `url(${BackgroundImage})`,
          backgroundPosition: "50% 0",
          backgroundSize: "cover",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Paper
          component="form"
          onSubmit={handleLogin}
          sx={{
            p: "2rem",
            width: "24rem",
            maxWidth: "95%",
            zIndex: "1",
          }}
        >
          <Stack spacing={2}>
            {/* <img
              // src={Logo}
              alt="logo"
              width="100"
              style={{ alignSelf: "center", padding: 0 }}
            /> */}
            <Typography
              align="center"
              component="h1"
              variant="h5"
              sx={{ mb: 1 }}
            >
              Sign in
            </Typography>
            <TextField
              name="username"
              label="Username"
              variant="outlined"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              name="password"
              label="Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
            />
            <Button variant="contained" color="primary" type="submit">
              Sign In
            </Button>
            {message && <Alert severity="error">{message}</Alert>}
          </Stack>
        </Paper>
      </Box>
  );
};

export default Login;
