import React from "react";
import { Autocomplete, TextField, Chip } from "@mui/material";

const TagInput = ({ tags, setTags, label = "", placeholder = "" }) => {
  return (
    <Autocomplete
      multiple
      freeSolo
      size="small"
      options={[]}
      value={tags}
      onChange={(event, newValue) => {
        setTags(newValue);
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const tagProps = getTagProps({ index });
          delete tagProps.key; // Remove key from tagProps
          return (
            <Chip key={index} variant="outlined" label={option} {...tagProps} />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default TagInput;
