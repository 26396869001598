function decodeHTMLEntities(text) {
  var textArea = document.createElement("textarea");
  textArea.innerHTML = text;
  return textArea.value;
}
function extractCode(url = "") {
  const regex = /\/([^\/]+)\/([^\/]+)\/[^\/]+\/$/;
  const match = url?.match?.(regex);
  return match ? match[2] : null;
}

function htmlToPlainText(html = "") {
  // Replace <br> tags with newline characters
  let text = html?.replace(/<br\s*\/?>/gi, "\n");

  // Remove all other HTML tags
  text = text?.replace(/<\/?[^>]+(>|$)/g, "");

  return text;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { decodeHTMLEntities, extractCode, htmlToPlainText };
