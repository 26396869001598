// src/App.js
import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, useRoutes, Navigate } from "react-router-dom";

import {
  Container,
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { onMessageListener } from "./firebase-config";

import NewsCards from "./components/NewsCards";
import Login from "./components/Login";
import Header from "./components/Header";
import Settings from "./components/Settings";
import AuthService from "./services/AuthService";
import BseNewsCards from "./components/BseNewsCards";

const AppRoutes = ({ currentUser, handleLogin, handleLogout }) => {
  const [tabIndex, setTabIndex] = useState(0);
  return useRoutes(
    currentUser
      ? [
          {
            path: "/news",
            element: (
              <Box>
                <Header handleLogout={handleLogout}>
                  {tabIndex === 0 && <NewsCards />}
                  {tabIndex === 1 && <BseNewsCards />}
                  <Paper
                    sx={{
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      height: "51px",
                    }}
                    elevation={3}
                  >
                    <BottomNavigation
                      value={tabIndex}
                      onChange={(_, newValue) => {
                        setTabIndex(newValue);
                      }}
                      showLabels
                      style={{ width: "100%", height: "51px" }}
                    >
                      <BottomNavigationAction
                        label="NSE"
                        // icon={<SearchIcon />}
                      />
                      <BottomNavigationAction
                        label="BSE"
                        // icon={<NotificationsIcon />}
                      />
                    </BottomNavigation>
                  </Paper>
                </Header>
              </Box>
            ),
          },
          {
            path: "/setting",
            element: (
              <Box>
                <Header handleLogout={handleLogout}>
                  <Settings />
                </Header>
              </Box>
            ),
          },
          {
            path: "*",
            element: <Navigate to="/news" />,
          },
        ]
      : [
          {
            path: "*",
            element: <Navigate to="/login" />,
          },
          {
            path: "/login",
            element: <Login onLogin={handleLogin} />,
          },
        ]
  );
};

const App = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
    }
  }, []);

  const Com = ({ title, description }) => (
    <div className="p-4 bg-white text-black" style={{ maxWidth: "500px" }}>
      <div className="font-bold text-lg">{title}</div>
      <div>{description}</div>
    </div>
  );

  const listenForMessages = useCallback(() => {
    onMessageListener()
      .then(({ notification }) => {
        console.log("Message received: ", notification);
        enqueueSnackbar(
          <Com
            title={notification?.title || ""}
            description={notification?.body || ""}
          />,
          { variant: "info" }
        );
        listenForMessages(); // re-run the listener after handling the message
      })
      .catch((err) => {
        console.log("Failed to receive message: ", err);
        setTimeout(() => listenForMessages(), 0);
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    listenForMessages();
  }, []);

  const handleLogin = useCallback(() => {
    const user = AuthService.getCurrentUser();
    setCurrentUser(user);
  }, []);

  const handleLogout = useCallback(() => {
    AuthService.logout();
    setCurrentUser(null);
  }, []);

  return (
    <Router>
      <Container
        component="main"
        style={{ padding: 0, margin: 0, maxWidth: "100vw" }}
      >
        <Box
          sx={{
            maxWidth: "100vw",
            minHeight: "100vh",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <AppRoutes
            currentUser={currentUser}
            handleLogin={handleLogin}
            handleLogout={handleLogout}
          />
        </Box>
      </Container>
    </Router>
  );
};

export default App;
