import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDai07OD6U3JRLOieZBN6-24RufyqSNZqw",
  authDomain: "pwa-push-test-1a76b.firebaseapp.com",
  projectId: "pwa-push-test-1a76b",
  storageBucket: "pwa-push-test-1a76b.appspot.com",
  messagingSenderId: "833651048949",
  appId: "1:833651048949:web:8ef8ed347f80125b46e0bc",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


export const requestFirebaseNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      const token = await getToken(messaging, {
        vapidKey:
          "BA-dUOTpNEp3YwcZvqmcxmFXPQRk-2MuNe7KWi0CjXRaursNJMydLTcG8EPcPVTYhUCaHj3Qy5Bf7yqGGn_0AQg",
      });
      if (token) {
        console.log("FCM token:", token);
        return token;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    } else {
      console.log('Notification permission denied.');
      alert("Notification permission denied. Please enable your app notification for this app")
    }
  } catch (error) {
    console.error("An error occurred while retrieving token. ", error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
