import React, { useCallback, useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import TagInput from "./customComponents/TagInput";
import FiltersServices from "../services/FiltersServices";
import NotificationService from "../services/NotificationService";
import { Button, CircularProgress } from "@mui/material";
import { requestFirebaseNotificationPermission } from "../firebase-config";

export default function Settings() {
  const [keywords, setKeywords] = useState([]);
  const [symbols, setSymbols] = useState([]);
  const existingToken = localStorage.getItem("subscriptionToken");
  const [token, setToken] = useState(existingToken);
  const [takingPermission, setTakingPermission] = useState(false);

  const handleKeywordChange = useCallback(async (newKeywords) => {
    await FiltersServices.updateKeywords(newKeywords);
    setKeywords(newKeywords);
  }, []);

  const handleSymbolChange = useCallback(async (newKeywords) => {
    await FiltersServices.updateSymbols(newKeywords);
    setSymbols(newKeywords);
  }, []);

  const requestPermission = useCallback(async () => {
    setTakingPermission(true)
    const subscriptionToken = await requestFirebaseNotificationPermission();
    if(subscriptionToken) {
      await NotificationService.subscribeUser(subscriptionToken);
      setToken(subscriptionToken);
    }
    setTakingPermission(false)
  }, [setTakingPermission,setToken ]);

  const abortPermission = useCallback(async () => {
    setToken();
    NotificationService.unsubscribeUser(token);
  }, [token]);

  useEffect(() => {
    FiltersServices.getKeywords(setKeywords);
    FiltersServices.getSymbols(setSymbols);
  }, []);

  return (
    <Grid container sx={{ padding: 2, paddingTop: 0 }}>
      <Grid item xs={12} sx={{ paddingY: 2 }}>
        {!token ? 
          takingPermission ? <CircularProgress />:
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={requestPermission}
        >
          Enable Notification
        </Button>: 
        <Button
          color="secondary"
          variant="contained"
          size="small"
          onClick={abortPermission}
        >
          Disable Notification
        </Button>
        }
      </Grid>
      <Grid item xs={12} sx={{ paddingY: 2 }}>
        <TagInput
          label="Symbols"
          placeholder="Add Symbols"
          tags={symbols}
          setTags={handleSymbolChange}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingY: 2 }}>
        <TagInput
          label="Keywords"
          placeholder="Add Keywords"
          tags={keywords}
          setTags={handleKeywordChange}
        />
      </Grid>
    </Grid>
  );
}
