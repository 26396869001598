// src/App.js
import React from "react";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import {
  AppBar,
  Toolbar,
  Typography,
  SpeedDial,
  SpeedDialAction,
  Paper,
} from "@mui/material";
// import AuthService from "../services/AuthService";
import { ReactComponent as Logo } from "../assets/images/logo.svg"; // Import your custom SVG logo

const actions = [
  { icon: <HomeIcon />, name: "Home", path: "/news" },
  { icon: <SettingsIcon />, name: "Setting", path: "/setting" },
  { icon: <LogoutIcon />, name: "Logout", path: "/login" },
];

const Header = ({ children, handleLogout }) => {
  const navigate = useNavigate();

  return (
    <Paper sx={{ width: "100vw", height: "100vh" }}>
      <AppBar
        position="static"
       
      >
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{
            position: "absolute",
            top: 5,
            right: 0,
            ".MuiSpeedDial-fab": {
              width: "36px",
              height: "36px",
            },
          }}
          direction="down"
          icon={<MenuIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => {
                if (action.name === "Logout") {
                  handleLogout();
                } else {
                  navigate(action.path);
                }
              }}
            />
          ))}
        </SpeedDial>
        <Toolbar variant="dense">
          <Logo
            style={{ width: "40px", height: "40px", marginRight: "10px" }}
          />{" "}
          {/* Add your SVG logo here */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {"CFA"}
          </Typography>
        </Toolbar>
      </AppBar>
      {children}
    </Paper>
  );
};

export default Header;
