import axios from "axios";
import CONFIG from "../config";

const API_URL = CONFIG.API_URL;

const updateKeywords = async (keywords) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    try {
      await axios.post(
        `${API_URL}/updateKeywords`,
        { keywords },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
    } catch (error) {
      console.error("Error Updating keywords:", error);
    }
  }
};

const updateSymbols = async (symbols) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    try {
      await axios.post(
        `${API_URL}/updateSymbols`,
        { symbols },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
    } catch (error) {
      console.error("Error Updating Symbols:", error);
    }
  }
};

const getKeywords = async (setKeywords, setLoading = () => {}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    try {
      setLoading(true);
      const { data } = await axios.get(`${API_URL}/getKeywords`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setKeywords(data?.keywords);
    } catch (error) {
      console.error("Error in getting keywords:", error);
    } finally {
      setLoading(false);
    }
  }
};

const getSymbols = async (setSymbols, setLoading = () => {}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    try {
      setLoading(true);
      const { data } = await axios.get(`${API_URL}/getSymbols`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setSymbols(data?.symbols);
    } catch (error) {
      console.error("Error in getting Symbols:", error);
    } finally {
      setLoading(false);
    }
  }
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  updateKeywords,
  updateSymbols,
  getKeywords,
  getSymbols,
};
