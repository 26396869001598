import React, { useCallback, useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";

export default function SearchBar({
  onInputChange = () => {},
  onRefresh = () => {},
}) {
  const [inputValue, setInputValue] = useState("");

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedOnInputChange = useCallback(
    debounce(onInputChange, 800),
    [onInputChange],
    []
  );

  const onInputChangeHandle = (e) => {
    const value = e.target.value;
    setInputValue(value);
    debouncedOnInputChange(value);
  };

  return (
    <Paper
      // component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        margin: "5px",
      }}
    >
      {/* <IconButton sx={{ p: "10px" }} aria-label="menu">
        <MenuIcon />
      </IconButton> */}
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
        onChange={onInputChangeHandle}
        value={inputValue}
      />
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton
        onClick={onRefresh}
        color="primary"
        sx={{ p: "10px" }}
        aria-label="refresh"
      >
        <RefreshRoundedIcon />
      </IconButton>
    </Paper>
  );
}
