import axios from "axios";
import CONFIG from "../config";

const subscribeUser = async (subscription) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    const oldSubscription = await localStorage.getItem("subscriptionToken");
    try {
      await axios.post(
        `${CONFIG.API_URL}/subscribe`,
        { subscription, oldSubscription },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      localStorage.setItem("subscriptionToken", subscription);
    } catch (error) {
      console.error("Error subscribing user:", error);
    }
  }
};

const unsubscribeUser = async (subscription) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    try {
      await axios.post(
        `${CONFIG.API_URL}/unsubscribe`,
        { subscription },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      localStorage.removeItem("subscriptionToken");
    } catch (error) {
      console.error("Error subscribing user:", error);
    }
  }
};

export default {
  subscribeUser,
  unsubscribeUser
};
