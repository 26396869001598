import React, { useCallback, useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Fab, Link } from "@mui/material";
import moment from "moment";
import NewsService from "../services/NewsService";
import CircularIndeterminate from "./customComponents/CircularIndeterminate";
import utils from "../utils";
import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";

export default function BseNewsCards() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isTabActive, setIsTabActive] = useState(true);
  const intervalRef = useRef(null);

  const getNews = useCallback((setLoader) => {
    NewsService.getBseNews(setRows, setLoader);
  }, []);

  useEffect(() => {
    getNews(setLoading);
  }, []);

  // Function to start the interval
  const startInterval = useCallback(() => {
    intervalRef.current = setInterval(() => {
      if (isTabActive) {
        getNews();
      }
    }, 10000); // 10 seconds
  }, [isTabActive, getNews]);

  // Function to stop the interval
  const stopInterval = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  // Handle visibility change
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === "visible");
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Start the interval on component mount
    startInterval();

    // Cleanup
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      stopInterval();
    };
  }, [isTabActive]);

  return loading ? (
    <CircularIndeterminate />
  ) : (
    <>
      <Fab
        color="primary"
        aria-label="refresh"
        sx={{ position: "absolute", bottom: 62, right: 16 }}
        onClick={() => getNews(setLoading)}
      >
        <RefreshIcon />
      </Fab>
      <Grid
        container
        spacing={2}
        style={{
          marginTop: 3,
          overflowY: "scroll",
          maxHeight: "calc(100vh - 105px)",
          height: "calc(100vh - 105px)",
        }}
      >
        {rows.map((row, rowIndex) => {
          let symbol = utils.extractCode(row?.NSURL);
          return (
            <Grid item xs={12} sm={6} md={4} key={rowIndex}>
              <Card
                style={{
                  marginLeft: "3px",
                  marginRight: "3px",
                }}
              >
                <CardContent>
                  <div style={{ marginBottom: "10px" }}>
                    <Typography
                      variant="h6"
                      sx={{ textTransform: "uppercase" }}
                    >
                      <Link
                        href={`https://www.screener.in/company/${row?.SCRIP_CD}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {`${symbol} (${row?.SCRIP_CD})`}
                      </Link>
                    </Typography>

                    <Typography
                      color="primary"
                      variant="caption"
                      display="block"
                      gutterBottom
                    >
                      {row?.SLONGNAME}
                    </Typography>
                    {row?.CATEGORYNAME && (
                      <Typography
                        color="#757575"
                        variant="caption"
                        display="block"
                        gutterBottom
                        sx={{
                          fontStyle: "italic",
                        }}
                      >
                        <ClassOutlinedIcon
                          style={{ marginRight: 3, fontSize: "15px" }}
                        />
                        {row?.CATEGORYNAME}
                      </Typography>
                    )}
                  </div>
                  <div style={{ marginBottom: "5px" }}>
                    <Typography>{row?.MORE || row?.HEADLINE}</Typography>
                  </div>
                </CardContent>
                <CardActions
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: "7px",
                    marginRight: "7px",
                    alignItems: "end",
                  }}
                >
                  <Typography variant="caption">
                    {moment(row?.NEWS_DT).format("DD-MMM-YYYY hh:mm A")}
                  </Typography>
                  <Typography variant="subtitle2">
                    <Link
                      href={`https://www.bseindia.com/xml-data/corpfiling/AttachLive/${row?.ATTACHMENTNAME}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Attachment
                    </Link>
                  </Typography>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
