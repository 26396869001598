// src/services/AuthService.js
import axios from "axios";
import CONFIG from "../config";


const login = async (username, password) => {
  const response = await axios.post(`${CONFIG.API_URL}/login`, { username, password });
  if (response.data.token) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  logout,
  getCurrentUser,
};
